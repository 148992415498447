/* eslint-disable default-param-last */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
import { createReducer, createActions } from 'reduxsauce';
import findAddressLoja from '../../utils/findAddressLoja';
import formPagamentoData from '../../utils/formPagamento';

const formPagamentoInicial = formPagamentoData[0];

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getPecasRequest: ['nextPage'],
  getPecasSuccess: ['data'],
  getSearchPecasRequest: ['name', 'nextPage'],
  getSearchPecasSuccess: ['data'],
  setSecondaryPecas: ['data'],
  setSearchPecas: ['data'],
  clearSearchPecasRequest: null,
  clearSearchPecasSuccess: ['data', 'secondaryData'],
  finishOrcamentoRequest: ['data'],
  finishOrcamentoSuccess: null,
  getCardDataRequest: null,
  getCardDataSuccess: ['data'],
  updateCardDataRequest: ['data'],
  updateCardDataSuccess: ['data'],
  loadingCancel: null,
  handleItemQtdRequest: ['ref', 'action', 'qtdRow'],
  handleItemQtdSuccess: ['data'],
  handleClearItemQtdRequest: ['ref'],
  handleClearItemQtdSuccess: ['data'],
  handleFormPgtRequest: ['value'],
  handleFormPgtSuccess: ['data'],
  setLoading: ['data'],
  setSideCartStatus: ['data'],
  setLoja: ['data'],
  setCart: ['data'],
  setCartStep: ['data'],
  setCarrinho: ['data'],
  clearPrices: null,
  clearData: null,
});

export const PecasTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = {
  pecasData: null,
  cartData: null,
  cartDataPersist: null,
  secondaryData: null,
  isSearchPecas: false,
  lojaSelect: null,
  lojaAddress: null,
  carrinho: null,
  totalPrice: 0,
  totalPriceFator: 0,
  cartStep: 1,
  formPgmData: formPagamentoData,
  formPgmSelected: formPagamentoInicial,
  sideCartStatus: false,
  loading: false,
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PECAS_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),

  [Types.GET_PECAS_SUCCESS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    loading: false,
    pecasData: data,
  }),

  [Types.GET_SEARCH_PECAS_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),

  [Types.GET_SEARCH_PECAS_SUCCESS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    loading: false,
    pecasData: data,
  }),

  [Types.SET_SECONDARY_PECAS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    loading: false,
    secondaryData: data,
  }),

  [Types.SET_SEARCH_PECAS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    loading: false,
    isSearchPecas: data,
  }),

  [Types.FINISH_ORCAMENTO_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),

  [Types.FINISH_ORCAMENTO_SUCCESS]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
    pecasData: null,
    cartData: null,
    secondaryData: null,
    isSearchPecas: false,
    lojaSelect: null,
    lojaAddress: null,
    carrinho: null,
    totalPrice: 0,
    totalPriceFator: 0,
    cartStep: 4,
  }),

  [Types.GET_CARD_DATA_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
  }),

  [Types.GET_CARD_DATA_SUCCESS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    loading: false,
    cartDataPersist: data,
  }),

  [Types.UPDATE_CARD_DATA_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),

  [Types.UPDATE_CARD_DATA_SUCCESS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    loading: false,
    cartDataPersist: data,
  }),

  [Types.CLEAR_SEARCH_PECAS_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),

  [Types.CLEAR_SEARCH_PECAS_SUCCESS]: (
    { data },
    secondaryData,
    state = INITIAL_STATE,
  ) => ({
    ...state,
    loading: false,
    pecasData: data,
    secondaryData,
  }),

  [Types.HANDLE_ITEM_QTD_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),

  [Types.HANDLE_ITEM_QTD_SUCCESS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    pecasData: {
      ...state.pecasData,
      docs: data.pecasDataDocs,
    },
    cartData: data.cartData,
    totalPrice: data.totalPrice,
    totalPriceFator: data.totalPriceFator,
  }),

  [Types.HANDLE_CLEAR_ITEM_QTD_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),

  [Types.HANDLE_CLEAR_ITEM_QTD_SUCCESS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    pecasData: {
      ...state.pecasData,
      docs: data.pecasDataDocs,
    },
    cartData: data.cartData,
    totalPrice: data.totalPrice,
    totalPriceFator: data.totalPriceFator,
  }),

  [Types.HANDLE_FORM_PGT_REQUEST]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),

  [Types.HANDLE_FORM_PGT_SUCCESS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    loading: false,
    formPgmSelected: data.formPgmSelected,
    totalPriceFator: data.totalPriceFator,
  }),

  [Types.LOADING_CANCEL]: (state = INITIAL_STATE) => ({
    ...state,
    loading: false,
  }),

  [Types.SET_CART_STEP]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    cartStep: data,
  }),

  [Types.SET_SIDE_CART_STATUS]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    sideCartStatus: data,
  }),

  [Types.SET_LOADING]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    loading: data,
  }),

  [Types.SET_LOJA]: (state = INITIAL_STATE, { data }) => {
    const addressLoja = findAddressLoja(data);

    return ({
      ...state,
      lojaSelect: data,
      lojaAddress: addressLoja,
    });
  },

  [Types.SET_CARRINHO]: (state = INITIAL_STATE, { data }) => ({
    ...state,
    carrinho: data,
  }),

  [Types.CLEAR_PRICES]: (state = INITIAL_STATE) => ({
    ...state,
    totalPrice: 0,
    totalPriceFator: 0,
  }),

  [Types.CLEAR_DATA]: (state = INITIAL_STATE) => ({
    ...state,
    pecasData: null,
    cartData: null,
    cartDataPersist: null,
    secondaryData: null,
    isSearchPecas: false,
    lojaSelect: null,
    lojaAddress: null,
    carrinho: null,
    totalPrice: 0,
    totalPriceFator: 0,
    cartStep: 1,
    formPgmData: formPagamentoData,
    formPgmSelected: formPagamentoInicial,
    sideCartStatus: false,
    loading: false,
  }),
});
