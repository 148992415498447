export default [
  {
    CODIGO: '01',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20191003',
    DATAINCLUS: '19950503',
    DESCRICAO: 'A Vista',
    EFETIFINAN: true,
    EQUIPAMENT: true,
    HORAALTERA: '10:20:06',
    HORAINCLUS: '19:56:58',
    OUTRAS: true,
    PECAS: true,
    REVISAOALT: '0110Qia',
    SERVICOS: true,
    nFator: 1,
    nParcelas: 1,
    recno: 1,
  },
  {
    CODIGO: '70',
    CODIGOBANC: '777',
    CODOPERALT: '99999',
    CODOPERINC: '00053',
    DATAALTERA: '20190912',
    DATAINCLUS: '20020325',
    DESCRICAO: 'Cartao de Debito',
    EFETIFINAN: true,
    EQUIPAMENT: true,
    HORAALTERA: '18:02:56',
    HORAINCLUS: '14:24:33',
    OUTRAS: true,
    PARCELA001: 1,
    PECAS: true,
    REVISAOALT: '0110Qia',
    SEMCRITICA: true,
    SERVICOS: true,
    nFator: 1,
    nParcelas: 1,
    recno: 32,
  },
  {
    CODIGO: '51',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20210301',
    DATAINCLUS: '20090824',
    DESCRICAO: 'Boleto 1 x',
    EQUIPAMENT: true,
    HORAALTERA: '11:00:08',
    HORAINCLUS: '18:17:12',
    LIMITEINFE: 100.00,
    OUTRAS: true,
    PARCELA001: 30,
    PARCFIXMEI: 27,
    PECAS: true,
    REVISAOALT: '0110Qia',
    TAXADEJURO: 0.8000,
    UTIPARCFIX: true,
    nFator: 1.008000000000,
    nParcelas: 1,
    recno: 45,
  },
  {
    CODIGO: '52',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20210301',
    DATAINCLUS: '20090824',
    DESCRICAO: 'Boleto 2 x',
    EQUIPAMENT: true,
    HORAALTERA: '11:00:23',
    HORAINCLUS: '18:17:23',
    LIMITEINFE: 200.00,
    OUTRAS: true,
    PARCELA001: 30,
    PARCELA002: 60,
    PARCFIXMEI: 27,
    PECAS: true,
    REVISAOALT: '0110Qia',
    TAXADEJURO: 0.8000,
    UTIPARCFIX: true,
    nFator: 1.012015936255,
    nParcelas: 2,
    recno: 46,
  },
  {
    CODIGO: '53',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20210301',
    DATAINCLUS: '20090824',
    DESCRICAO: 'Boleto 3 x',
    EQUIPAMENT: true,
    HORAALTERA: '11:00:31',
    HORAINCLUS: '18:17:38',
    LIMITEINFE: 300.00,
    OUTRAS: true,
    PARCELA001: 30,
    PARCELA002: 60,
    PARCELA003: 90,
    PARCFIXMEI: 27,
    PECAS: true,
    REVISAOALT: '0110Qia',
    TAXADEJURO: 0.8000,
    UTIPARCFIX: true,
    nFator: 1.016042496455,
    nParcelas: 3,
    recno: 47,
  },
  {
    CODIGO: '54',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20200415',
    DATAINCLUS: '20090824',
    DESCRICAO: 'Boleto 4 x',
    EQUIPAMENT: true,
    HORAALTERA: '16:13:53',
    HORAINCLUS: '18:17:52',
    LIBERAR120: true,
    LIMITEINFE: 400.00,
    OUTRAS: true,
    PARCELA001: 30,
    PARCELA002: 60,
    PARCELA003: 90,
    PARCELA004: 120,
    PARCFIXMEI: 27,
    PECAS: true,
    REVISAOALT: '0110Qia',
    SERVICOS: true,
    TAXADEJURO: 1.5000,
    UTIPARCFIX: true,
    nFator: 1.037779143953,
    nParcelas: 4,
    recno: 48,
  },
  {
    CODIGO: '55',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20200415',
    DATAINCLUS: '20010403',
    DESCRICAO: 'Boleto 5 x',
    EQUIPAMENT: true,
    HORAALTERA: '16:13:41',
    HORAINCLUS: '16:14:00',
    LIMITEINFE: 500.00,
    OUTRAS: true,
    PARCELA001: 30,
    PARCELA002: 60,
    PARCELA003: 90,
    PARCELA004: 120,
    PARCELA005: 150,
    PECAS: true,
    REVISAOALT: '0110Qia',
    SERVICOS: true,
    TAXADEJURO: 1.5000,
    UTIPARCFIX: true,
    nFator: 1.045446615476,
    nParcelas: 5,
    recno: 27,
  },
  {
    CODIGO: '56',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20200415',
    DATAINCLUS: '20100419',
    DESCRICAO: 'Boleto 6 x',
    EQUIPAMENT: true,
    HORAALTERA: '16:14:22',
    HORAINCLUS: '10:42:22',
    LIMITEINFE: 600.00,
    OUTRAS: true,
    PARCELA001: 30,
    PARCELA002: 60,
    PARCELA003: 90,
    PARCELA004: 120,
    PARCELA005: 150,
    PARCELA006: 180,
    PARCFIXMEI: 27,
    PECAS: true,
    REVISAOALT: '0110Qia',
    SERVICOS: true,
    TAXADEJURO: 1.5000,
    UTIPARCFIX: true,
    nFator: 1.053151287772,
    nParcelas: 6,
    recno: 49,
  },
  {
    CODIGO: '71',
    CODIGOBANC: '777',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20190912',
    DATAINCLUS: '20160629',
    DESCRICAO: 'Cartao 1 x',
    EFETIFINAN: true,
    EQUIPAMENT: true,
    HORAALTERA: '18:00:39',
    HORAINCLUS: '11:08:02',
    OUTRAS: true,
    PARCELA001: 30,
    PECAS: true,
    REVISAOALT: '0110Qia',
    REVISAOINC: '0110Qhw',
    SEMCRITICA: true,
    SERVICOS: true,
    nFator: 1,
    nParcelas: 1,
    recno: 63,
  },
  {
    CODIGO: '72',
    CODIGOBANC: '777',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20210707',
    DATAINCLUS: '20160629',
    DESCRICAO: 'Cartao 2 x',
    EFETIFINAN: true,
    EQUIPAMENT: true,
    HORAALTERA: '16:27:45',
    HORAINCLUS: '11:11:52',
    OUTRAS: true,
    PARCELA001: 30,
    PARCELA002: 60,
    PECAS: true,
    REVISAOALT: '0110Qia',
    REVISAOINC: '0110Qhw',
    SEMCRITICA: true,
    SERVICOS: true,
    nFator: 1,
    nParcelas: 2,
    recno: 64,
  },
  {
    CODIGO: '73',
    CODIGOBANC: '777',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20190912',
    DATAINCLUS: '20160629',
    DESCRICAO: 'Cartao 3 x',
    EFETIFINAN: true,
    EQUIPAMENT: true,
    HORAALTERA: '18:01:18',
    HORAINCLUS: '11:12:17',
    OUTRAS: true,
    PARCELA001: 30,
    PARCELA002: 60,
    PARCELA003: 90,
    PECAS: true,
    REVISAOALT: '0110Qia',
    REVISAOINC: '0110Qhw',
    SEMCRITICA: true,
    SERVICOS: true,
    nFator: 1,
    nParcelas: 3,
    recno: 65,
  },
  {
    CODIGO: '74',
    CODIGOBANC: '777',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20200317',
    DATAINCLUS: '20160629',
    DESCRICAO: 'Cartao 4 x',
    EFETIFINAN: true,
    EQUIPAMENT: true,
    HORAALTERA: '17:31:46',
    HORAINCLUS: '11:12:52',
    OUTRAS: true,
    PARCELA001: 30,
    PARCELA002: 60,
    PARCELA003: 90,
    PARCELA004: 120,
    PECAS: true,
    REVISAOALT: '0110Qia',
    REVISAOINC: '0110Qhw',
    SEMCRITICA: true,
    SERVICOS: true,
    TAXADEJURO: 1.5000,
    UTIPARCFIX: true,
    nFator: 1.037779143953,
    nParcelas: 4,
    recno: 66,
  },
  {
    CODIGO: '75',
    CODIGOBANC: '777',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20200317',
    DATAINCLUS: '20160706',
    DESCRICAO: 'Cartao 5 x',
    EFETIFINAN: true,
    EQUIPAMENT: true,
    HORAALTERA: '17:31:35',
    HORAINCLUS: '18:54:53',
    OUTRAS: true,
    PARCELA001: 30,
    PARCELA002: 60,
    PARCELA003: 90,
    PARCELA004: 120,
    PARCELA005: 150,
    PECAS: true,
    REVISAOALT: '0110Qia',
    REVISAOINC: '0110Qhw',
    SEMCRITICA: true,
    SERVICOS: true,
    TAXADEJURO: 1.5000,
    UTIPARCFIX: true,
    nFator: 1.045446615476,
    nParcelas: 5,
    recno: 68,
  },
  {
    CODIGO: '76',
    CODIGOBANC: '777',
    CODOPERALT: '99999',
    CODOPERINC: '99999',
    DATAALTERA: '20200317',
    DATAINCLUS: '20160706',
    DESCRICAO: 'Cartao 6 x',
    EFETIFINAN: true,
    EQUIPAMENT: true,
    HORAALTERA: '17:32:01',
    HORAINCLUS: '18:55:10',
    OUTRAS: true,
    PARCELA001: 30,
    PARCELA002: 60,
    PARCELA003: 90,
    PARCELA004: 120,
    PARCELA005: 150,
    PARCELA006: 180,
    PECAS: true,
    REVISAOALT: '0110Qia',
    REVISAOINC: '0110Qhw',
    SEMCRITICA: true,
    SERVICOS: true,
    TAXADEJURO: 1.5000,
    UTIPARCFIX: true,
    nFator: 1.053151287772,
    nParcelas: 6,
    recno: 69,
  },
];
