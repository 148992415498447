module.exports = [
  {
    nomeCompleto: 'ALINE RAMOS PEREIRA ALINE',
    nome: 'ALINE RAMOS',
    codVendedor: '52236',
    fone: '(067) 3041-2657',
    cel: '(067) 99836-3419',
    email: 'aline.ramos@tecnoeste.com.br,',
    loja: '0001',
  },
  {
    nomeCompleto: 'ANTONIO LUIZ VILANOVA BARRETO',
    nome: 'ANTONIO',
    codVendedor: '20029',
    fone: '(065) 3618-1340',
    cel: '(065) 99249-9414',
    email: 'antonio.vilanova@tecnoeste.com.br',
    loja: '0002',
  },
  {
    nomeCompleto: 'BENEDITO PAZ LEANDRO',
    nome: 'BENEDITO',
    codVendedor: '01002',
    fone: '(065) 3618-1347',
    cel: '(065) 99286-3211',
    email: 'benedito@tecnoeste.com.br',
    loja: '0002',
  },
  {
    nomeCompleto: 'CLAUDIA LEOBET',
    nome: 'CLAUDIA',
    codVendedor: '01058',
    fone: '(065) 3618-1346',
    cel: '(065) 99945-0881',
    email: 'claudialeobet@tecnoeste.com.br',
    loja: '0002',
  },
  {
    nomeCompleto: 'CLEBERSON CARDOSO BENITES',
    nome: 'CLEBERSON',
    codVendedor: '14004',
    fone: '(067) 3037-8600',
    cel: '(067) 99661-3851',
    email: 'cleberson.benites@tecnoeste.com.br',
    loja: '0014',
  },
  {
    nomeCompleto: 'CRISTIANO RODRIGUES ALVES',
    nome: 'CRISTIANO',
    codVendedor: '01499',
    fone: '(067) 3041-2688',
    cel: '(067) 99801-4449',
    email: 'cristiano.alves@tecnoeste.com.br',
    loja: '0001',
  },
  {
    nomeCompleto: 'DJALMA SANTOS S.DE CAMPOS',
    nome: 'DJALMA',
    codVendedor: '00176',
    fone: '(067) 3041-2688',
    cel: '(067) 99619-4115',
    email: 'djalma@tecnoeste.com.br',
    loja: '0001',
  },
  {
    nomeCompleto: 'EVILARIO ROBERTO DA SILVA',
    nome: 'EVILARIO',
    codVendedor: '10132',
    fone: '(065) 3618-1353',
    cel: '(065) 99233-0513',
    email: 'evilario.roberto@tecnoeste.com.br',
    loja: '0002',
  },
  {
    nomeCompleto: 'JONNY MAIKO FERREIRA',
    nome: 'JONNY',
    codVendedor: '54695',
    fone: '(065) 36181330',
    cel: '(065) 99808-4902',
    email: 'jonny.ferreira@tecnoeste.com.br',
    loja: '0002',
  },
  {
    nomeCompleto: 'LUCAS ALEXANDRE P. PETRAZZINI',
    nome: 'LUCAS',
    codVendedor: '13019',
    fone: '(066) 3531-4702',
    cel: '(066) 99668-4438',
    email: 'lucas.petrazzini@tecnoeste.com.br',
    loja: '0012',
  },
  {
    nomeCompleto: 'SANDRA MARA BATISTA NEVES',
    nome: 'SANDRA',
    codVendedor: '00227',
    fone: '(067) 3041-2658',
    cel: '(067) 9242-7221',
    email: 'sandra@tecnoeste.com.br',
    loja: '0014',
  },
  {
    nomeCompleto: 'SIDINEI MOREIRA',
    nome: 'SIDINEI',
    codVendedor: '89642',
    fone: '(067) 3041-2667',
    cel: '(067) 99818-4065',
    email: 'sidinei.moreira@tecnoeste.com.br',
    loja: '0001',
  },
  {
    nomeCompleto: 'ERISVALDO VIEIRA DOS SANTOS',
    nome: 'ERISVALDO',
    codVendedor: '65697',
    fone: '(066) 3531-4702',
    cel: '(066) 99623-9767',
    email: 'erisvaldo.santos@tecnoeste.com.br',
    loja: '0012',
  },
  {
    nomeCompleto: 'VINICIUS PERETTI',
    nome: 'VINICIUS',
    codVendedor: '12345',
    fone: '(066) 3531-4702',
    cel: '(066) 99623-9767',
    email: 'vinicius@basepro.com.br',
    loja: '0001',
  },
];
