/* eslint-disable import/no-useless-path-segments */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from '../../pages/Home';

function Routes() {
  return (
    <Switch>
      <Route exact path="/app" component={HomePage} />
    </Switch>
  );
}

export default Routes;
