/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React from 'react';
import { NotificationContainer } from 'react-notifications';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';

import './config/reactotron';
import store, { persistor } from './store';

import 'react-notifications/lib/notifications.css';
import Routes from './routes';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router basename="/w3">
          <Routes />
          <NotificationContainer />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
