/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
import { put, call, select } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';

import Cookies from 'universal-cookie';
import PecasActions from '../ducks/pecas';
import api from '../../services/api';
import apiFenix from '../../services/apiFenix';
import { formatFloat, valideDatePrommo } from '../../utils/formaters';
// import { formatFloat } from '../../utils/formaters';

export function* getPecas({ nextPage = false }) {
  const cartData = yield select((store) => store.pecas.cartData);

  function handleQtdItem(item) {
    if (!cartData) {
      return 0;
    }
    const findItem = cartData.filter((cartItem) => cartItem.REFERENCIA === item.REFERENCIA)[0];

    return findItem ? findItem.qtd : 0;
  }

  try {
    const pecasData = yield select((store) => store.pecas.pecasData);
    const lojaSelect = yield select((store) => store.pecas.lojaSelect);

    if (nextPage && pecasData.hasNextPage) {
      const { data } = yield call(apiFenix.get, `/app?l=${lojaSelect}&limit=20${(nextPage && pecasData.hasNextPage) ? `&page=${pecasData.nextPage}` : ''}`);
      const filterData = data.docs.map((item, index) => ({
        ...item,
        index,
        // newPrice: Math.round(((item.PRECO) * 0.9) * 100) / 100,
        // newPrice: formatFloat((item.PRECO * 0.9)),
        newPrice: valideDatePrommo(item.PROMOCAO, item.PRECO, item.DTVALIDPRO),
        qtd: handleQtdItem(item),
        imgData: `https://fenix.tecnoeste.net/imagens/pecas/slr-${item.SLR}-ref-${item.REFERENCIA}-cf--foto1.jpeg`,
      }));
      const customData = [...pecasData.docs, ...filterData];
      data.docs = customData;
      return yield put(PecasActions.getPecasSuccess(data));
    }
    const { data } = yield call(apiFenix.get, `/app?l=${lojaSelect}&limit=20${(nextPage && pecasData.hasNextPage) ? `&page=${pecasData.nextPage}` : ''}`);
    const filterData = data.docs.map((item, index) => ({
      ...item,
      index,
      // newPrice: Math.round(((item.PRECO) * 0.9) * 100) / 100,
      // newPrice: formatFloat((item.PRECO * 0.9)),
      newPrice: valideDatePrommo(item.PROMOCAO, item.PRECO, item.DTVALIDPRO),
      qtd: handleQtdItem(item),
      imgData: `https://fenix.tecnoeste.net/imagens/pecas/slr-${item.SLR}-ref-${item.REFERENCIA}-cf--foto1.jpeg`,
    }));

    data.docs = filterData;
    return yield put(PecasActions.getPecasSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

export function* searchPecas({ name, nextPage = false }) {
  const cartData = yield select((store) => store.pecas.cartData);

  const handleQtdItem = (item) => {
    const findItem = cartData.filter((cartItem) => cartItem.REFERENCIA === item.REFERENCIA)[0];

    return findItem ? findItem.qtd : 0;
  };

  try {
    const pecasData = yield select((store) => store.pecas.pecasData);
    const lojaSelect = yield select((store) => store.pecas.lojaSelect);
    const isSearchPecas = yield select((store) => store.pecas.isSearchPecas);

    if (nextPage && pecasData.hasNextPage) {
      const { data } = yield call(apiFenix.get, `/app?l=${lojaSelect}&limit=20&search=${name.toUpperCase()}${(nextPage && pecasData.hasNextPage) ? `&page=${pecasData.nextPage}` : ''}`);
      const filterData = data.docs.map((item, index) => ({
        ...item,
        index,
        // newPrice: Math.round(((item.PRECO) * 0.9) * 100) / 100,
        newPrice: valideDatePrommo(item.PROMOCAO, item.PRECO, item.DTVALIDPRO),
        qtd: handleQtdItem(item),
        imgData: `https://fenix.tecnoeste.net/imagens/pecas/slr-${item.SLR}-ref-${item.REFERENCIA}-cf--foto1.jpeg`,
      }));
      const customData = [...pecasData.docs, ...filterData];
      data.docs = customData;

      if (isSearchPecas) {
        return yield put(PecasActions.getSearchPecasSuccess(data));
      }

      yield put(PecasActions.setSearchPecas(true));
      yield put(PecasActions.setSecondaryPecas(pecasData));
      return yield put(PecasActions.getSearchPecasSuccess(data));
    }

    const { data } = yield call(apiFenix.get, `/app?l=${lojaSelect}&limit=20&search=${name.toUpperCase()}`);
    const filterData = data.docs.map((item, index) => ({
      ...item,
      index,
      // newPrice: Math.round(((item.PRECO) * 0.9) * 100) / 100,
      newPrice: valideDatePrommo(item.PROMOCAO, item.PRECO, item.DTVALIDPRO),
      // newPrice: formatFloat((item.PRECO * 0.9)),
      qtd: handleQtdItem(item),
      imgData: `https://fenix.tecnoeste.net/imagens/pecas/slr-${item.SLR}-ref-${item.REFERENCIA}-cf--foto1.jpeg`,
    }));

    data.docs = filterData;

    if (isSearchPecas) {
      return yield put(PecasActions.getSearchPecasSuccess(data));
    }

    yield put(PecasActions.setSearchPecas(true));
    yield put(PecasActions.setSecondaryPecas(pecasData));
    return yield put(PecasActions.getSearchPecasSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

export function* clearPecas() {
  try {
    const pecasData = yield select((store) => store.pecas.pecasData);
    const secondaryData = yield select((store) => store.pecas.secondaryData);

    const results = pecasData.docs.filter(({ REFERENCIA: ref1 }) => secondaryData.docs.some(({ REFERENCIA: ref2 }) => ref2 === ref1));

    if (results) {
      const filterSecondary = secondaryData.docs.map((item) => {
        const filterPecas = results.filter((resultItem) => resultItem.REFERENCIA === item.REFERENCIA)[0];

        return ({
          ...item,
          qtd: filterPecas ? filterPecas.qtd : item.qtd,
        });
      });

      secondaryData.docs = filterSecondary;

      yield put(PecasActions.clearSearchPecasSuccess(secondaryData, null));
      return yield put(PecasActions.setSearchPecas(false));
    }

    yield put(PecasActions.clearSearchPecasSuccess(secondaryData, null));
    return yield put(PecasActions.setSearchPecas(false));
  } catch (error) {
    console.log(error);
  }
}

export function* finishOrcamento({ data }) {
  try {
    const cartData = yield select((store) => store.pecas.cartData);
    const formPgmSelected = yield select((store) => store.pecas.formPgmSelected);
    const totalPrice = yield select((store) => store.pecas.totalPrice);
    const totalPriceFator = yield select((store) => store.pecas.totalPriceFator);
    const lojaSelectStore = yield select((store) => store.pecas.lojaSelect);
    // const clientDataStore = yield select((store) => store.pecas.clientData);

    const filterItens = totalPrice === 0 ? [] : cartData;
    const dateNow = format(Date.now(), 'dd/MM/yyyy - HH:mm:ss', { locale: ptBr });
    const finalData = {
      ...data,
      produtos: filterItens,
      loja: lojaSelectStore,
      total: formatFloat(totalPrice, true),
      totalMultFormPgt: formatFloat(totalPriceFator, true),
      dateLead: dateNow,
      formaPgmName: totalPrice === 0 ? '' : formPgmSelected.DESCRICAO,
      codFormaPgm: totalPrice === 0 ? '' : formPgmSelected.CODIGO,
      promoName: 'TECNOESTE - E-COMMERCE',
    };

    yield call(api.post, '/lead', finalData);
    NotificationManager.success(
      'Orçamento gerado com sucesso, em breve um vendedor ira entrar em contato',
      'Orçamento',
    );
    yield put(PecasActions.setCartStep(4));
    return yield put(PecasActions.finishOrcamentoSuccess());
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        NotificationManager.error(
          'Email informado para atualização já esta cadastrado.',
          'Orçamento',
        );
        return yield put(PecasActions.loadingCancel());
      }
    }
    NotificationManager.error(
      'Ops, algo deu errado, tente novamente',
      'Orçamento',
    );
    return yield put(PecasActions.loadingCancel());
  }
}

export function* createCart() {
  try {
    const cookie = new Cookies();
    const { data } = yield call(api.post, '/cart');

    cookie.set('ctkey', data.cart.cart_key);
    cookie.set('ctid', data.cart._id);
    cookie.set('ctexpin', data.cart.expire_in);

    return yield put(PecasActions.getCardDataSuccess({ ...data.cart }));
  } catch (error) {
    return yield put(PecasActions.loadingCancel());
  }
}

export function* updateCart() {
  try {
    const cartData = yield select((store) => store.pecas.cartData);
    const cartPersistData = yield select((store) => store.pecas.cartDataPersist);

    const newCartData = {
      ...cartPersistData,
      cart_data: cartData,
    };

    const { data } = yield call(api.put, '/cart', newCartData);

    return yield put(PecasActions.updateCardDataSuccess({ ...data.cart }));
  } catch (error) {
    console.log(error);
    return yield put(PecasActions.loadingCancel());
  }
}

export function* handleQtdItemSync({ ref, action, qtdRow }) {
  try {
    const cookie = new Cookies();
    const ctidCookie = cookie.get('ctid');

    const pecasDataStore = yield select((store) => store.pecas.pecasData);
    const secondaryDataStore = yield select((store) => store.pecas.secondaryData);
    const totalPriceStore = yield select((store) => store.pecas.totalPrice);
    const cartPersistData = yield select((store) => store.pecas.cartDataPersist);
    const formPgmSelected = yield select((store) => store.pecas.formPgmSelected);
    const lojaSelectStore = yield select((store) => store.pecas.lojaSelect);

    const filterQtdItem = [];

    if (action === 'row') {
      const filterQtdItemRow = pecasDataStore.docs.map((item) => (item.REFERENCIA === ref ? (
        {
          ...item,
          qtd: qtdRow,
        }
      ) : item));

      filterQtdItem.push(...filterQtdItemRow);
    } else {
      const filterQtdItemButton = pecasDataStore.docs.map((item) => (item.REFERENCIA === ref ? (
        {
          ...item,
          qtd: action === '+'
            ? (item.qtd ? item.qtd + 1 : 1)
            : (item.qtd && item.qtd > 0 && item.qtd - 1),
        }
      ) : item));

      filterQtdItem.push(...filterQtdItemButton);
    }

    const priceToItemFilter = filterQtdItem.filter(
      (item) => item.REFERENCIA === ref,
    )[0];

    let newUpdateTotalPrice = 0;

    if (action === '+') {
      newUpdateTotalPrice = totalPriceStore + priceToItemFilter.newPrice;
    } else if (action === 'row') {
      newUpdateTotalPrice = totalPriceStore + ((priceToItemFilter.newPrice * priceToItemFilter.qtd) - priceToItemFilter.newPrice);
    } else {
      newUpdateTotalPrice = (priceToItemFilter.newPrice > totalPriceStore
        ? 0
        : totalPriceStore - priceToItemFilter.newPrice
      );
    }

    const finalDataResult = [];

    const filterCartItem = filterQtdItem.filter((item) => item.qtd > 0);

    finalDataResult.push(...filterCartItem);

    if (secondaryDataStore) {
      const filterSecondaryData = secondaryDataStore.docs.filter((item) => item.qtd > 0);

      finalDataResult.push(...filterSecondaryData);
    }

    const finalDataCart = {
      pecasDataDocs: filterQtdItem,
      cartData: finalDataResult,
      totalPrice: newUpdateTotalPrice,
      totalPriceFator: newUpdateTotalPrice,
    };

    if (ctidCookie) {
      const newCartData = {
        ...cartPersistData,
        loja: lojaSelectStore,
        total: formatFloat(newUpdateTotalPrice, true),
        formaPgmName: totalPriceStore === 0 ? '' : formPgmSelected.DESCRICAO,
        codFormaPgm: totalPriceStore === 0 ? '' : formPgmSelected.CODIGO,
        cart_data: finalDataResult,
      };

      const { data } = yield call(api.put, '/cart', newCartData);

      yield put(PecasActions.updateCardDataSuccess({ ...data.cart }));
    }

    return yield put(PecasActions.handleItemQtdSuccess(finalDataCart));
  } catch (error) {
    console.log('error', error);
    if (error.response) {
      if (error.response.status === 401) {
        const cookie = new Cookies();
        cookie.remove('ctid');
        cookie.remove('ctexpin');
        cookie.remove('ctkey');
        yield put(PecasActions.loadingCancel());
        return yield call(createCart);
      }
    }
    return yield put(PecasActions.loadingCancel());
  }
}

export function* handleClearItemQtdSync({ ref }) {
  try {
    const cookie = new Cookies();
    const ctidCookie = cookie.get('ctid');

    const pecasDataStore = yield select((store) => store.pecas.pecasData);
    const secondaryDataStore = yield select((store) => store.pecas.secondaryData);
    const totalPriceStore = yield select((store) => store.pecas.totalPrice);
    const cartPersistData = yield select((store) => store.pecas.cartDataPersist);
    const formPgmSelected = yield select((store) => store.pecas.formPgmSelected);
    const lojaSelectStore = yield select((store) => store.pecas.lojaSelect);

    const filterOldItem = pecasDataStore.docs.filter(
      (item) => item.REFERENCIA === ref,
    )[0];
    const totalOldPrice = filterOldItem.newPrice * filterOldItem.qtd;
    const filterQtdItem = pecasDataStore.docs.map((item) => (item.REFERENCIA === ref ? (
      {
        ...item,
        qtd: 0,
      }
    ) : item));

    const updateTotalPrice = totalOldPrice > totalPriceStore
      ? 0
      : totalPriceStore - totalOldPrice;

    const finalDataResult = [];

    const filterCartItem = filterQtdItem.filter((item) => item.qtd > 0);

    finalDataResult.push(...filterCartItem);

    if (secondaryDataStore) {
      const filterSecondaryData = secondaryDataStore.docs.filter((item) => item.qtd > 0);

      finalDataResult.push(...filterSecondaryData);
    }

    const finalDataCart = {
      pecasDataDocs: filterQtdItem,
      cartData: finalDataResult,
      totalPrice: updateTotalPrice,
      totalPriceFator: updateTotalPrice,
    };

    if (ctidCookie) {
      const newCartData = {
        ...cartPersistData,
        loja: lojaSelectStore,
        total: formatFloat(updateTotalPrice, true),
        formaPgmName: totalPriceStore === 0 ? '' : formPgmSelected.DESCRICAO,
        codFormaPgm: totalPriceStore === 0 ? '' : formPgmSelected.CODIGO,
        cart_data: finalDataResult,
      };

      const { data } = yield call(api.put, '/cart', newCartData);

      yield put(PecasActions.updateCardDataSuccess({ ...data.cart }));
    }

    return yield put(PecasActions.handleItemQtdSuccess(finalDataCart));
  } catch (error) {
    console.log('error', error);
    if (error.response) {
      if (error.response.status === 401) {
        const cookie = new Cookies();
        cookie.remove('ctid');
        cookie.remove('ctexpin');
        cookie.remove('ctkey');
        yield put(PecasActions.loadingCancel());
        return yield call(createCart);
      }
    }
    return yield put(PecasActions.loadingCancel());
  }
}
export function* handleFormPgmtSync({ value }) {
  try {
    const cookie = new Cookies();
    const ctidCookie = cookie.get('ctid');

    const totalPriceStore = yield select((store) => store.pecas.totalPrice);
    const cartPersistData = yield select((store) => store.pecas.cartDataPersist);
    const formPgmData = yield select((store) => store.pecas.formPgmData);
    const lojaSelectStore = yield select((store) => store.pecas.lojaSelect);

    const formPgtSelect = formPgmData.filter((pgt) => pgt.CODIGO === value)[0];

    const { nFator, nParcelas } = formPgtSelect;

    const totalFormat = formatFloat(totalPriceStore);
    const valueParcelaFator = formatFloat((totalFormat * nFator) / nParcelas);
    const totalFinal = formatFloat((valueParcelaFator * nParcelas));

    const finalDataCart = {
      formPgmSelected: formPgtSelect,
      totalPriceFator: totalFinal,
    };

    if (ctidCookie) {
      const newCartData = {
        ...cartPersistData,
        loja: lojaSelectStore,
        total: formatFloat(totalFinal, true),
        formaPgmName: totalPriceStore === 0 ? '' : formPgtSelect.DESCRICAO,
        codFormaPgm: totalPriceStore === 0 ? '' : formPgtSelect.CODIGO,
      };

      const { data } = yield call(api.put, '/cart', newCartData);

      yield put(PecasActions.updateCardDataSuccess({ ...data.cart }));
    }

    return yield put(PecasActions.handleFormPgtSuccess(finalDataCart));
  } catch (error) {
    console.log('error', error);
    if (error.response) {
      if (error.response.status === 401) {
        const cookie = new Cookies();
        cookie.remove('ctid');
        cookie.remove('ctexpin');
        cookie.remove('ctkey');
        yield put(PecasActions.loadingCancel());
        return yield call(createCart);
      }
    }
    return yield put(PecasActions.loadingCancel());
  }
}
