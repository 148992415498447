import React, { forwardRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import ModalComponent from '../../../components/ModalComponent';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('pt-BR', ptBR);

function AbrirRevisaoModal({
  abrirRevisaoModal, setAbrirRevisaoModal, maquina,
}) {
  const [showMapMobile] = useState(false);

  const [startDate, setStartDate] = useState(new Date());

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="bg-primary py-2 px-4 rounded-md" onClick={onClick} ref={ref} type="button">
      <h3 className="text-secondary font-bold text-base">{value}</h3>
    </button>
  ));

  return (
    <div className="">
      {maquina && !showMapMobile && (
        <ModalComponent
          openState={abrirRevisaoModal}
          closeAction={() => {
            setAbrirRevisaoModal(!abrirRevisaoModal);
          }}
          width="100%"
          height="90%"
        >
          <div className="flex flex-col w-full h-full p-8">
            <div className="flex flex-row items-center justify-between w-full border-b-2 pb-2">
              <h3 className="font-semibold text-2xl">Abrir revisão</h3>
              <button
                className="flex flex-row items-center bg-primary py-2 px-4 rounded-md"
                type="button"
                onClick={() => {
                  setAbrirRevisaoModal(!abrirRevisaoModal);
                }}
              >
                <h3 className="text-secondary font-bold text-base">Fechar</h3>
              </button>
            </div>
            <div className="flex flex-col w-full items-start justify-between pt-4">
              <div className="md:w-3/6">
                <h3 className="font-bold text-2xl">Sua proxima revisão sera em XXX horas</h3>
              </div>
              <section className="flex flex-row items-center justify-start w-1/3 mt-4">
                <h3 className="font-bold text-primary w-full">
                  Data do agendamento:
                </h3>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
              </section>
              <section className="flex flex-row items-center justify-start w-1/3 mt-4">
                <h3 className="font-bold text-primary w-full">
                  Hora do agendamento:
                </h3>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  locale="pt-BR"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Horas"
                  dateFormat="h:mm aa"
                  customInput={<ExampleCustomInput />}
                />
              </section>

              <button
                className="flex flex-row items-center justify-center bg-primary py-2 rounded-md mt-8 w-full md:w-1/3"
                type="button"
                onClick={() => {
                  setAbrirRevisaoModal(!abrirRevisaoModal);
                }}
              >
                <h3 className="text-secondary font-bold text-base">Abrir revisão</h3>
              </button>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  );
}

export default AbrirRevisaoModal;
